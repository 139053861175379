var SpanishTraslations = {
    'Más': 'Más',
    'Empezar': 'Empezar',
    'Plantillas': 'Plantillas',
    'Documentos': 'Documentos',
    'Email Certificado': 'Email Certificado',
    'Firma Presencial': 'Firma Presencial',
    'Autofirma': 'Autofirma',
    'Notificaciones': 'Notificaciones',
    'Tipo de Cuenta': 'Tipo de Cuenta',
    'Administrador': 'Administrador',
    'Usuario': 'Usuario',
    'Plan Actual': 'Plan Actual',
    'Bono Activo': 'Bono Activo',
    'No tienes ninguna suscripción activa': 'No tienes ninguna suscripción activa',
    'Modificar firma': 'Modificar firma',
    'Mi Cuenta': 'Mi Cuenta',
    'Calendario': 'Calendario',
    'Soporte': 'Soporte',
    'Cerrar Sesión': 'Cerrar Sesión',
    'Volver': 'Volver',
    'Personaliza tu plan de subscripción mensual': 'Personaliza tu plan de subscripción mensual',
    'mes': 'mes',
    'o': 'o',
    'por año': 'por año',
    'Comprar': 'Comprar',
    'Usuarios': 'Usuarios',
    'Firmas': 'Firmas',
    'Recordatorios y Notificaciones': 'Envía Recordatorios',
    'Marca Personalizada': 'Marca Personalizada',
    'Envío Programado': 'Programa tus envios',
    'Envío por Email': 'Envío por Email',
    'API': 'API',
    'Más info': 'Más info',
    '¿Necesitas más? Añade uno de nuestros bonos a tu plan': '¿Necesitas más? Añade uno de nuestros bonos a tu plan',
    'Pagar Ahora': 'Pagar Ahora',
    'Realizar Pago': 'Realizar Pago',
    'Infromación del Plan': 'Infromación del Plan',
    'Producto': 'Producto',
    'Plan': 'Plan',
    'Detalles': 'Detalles',
    'Precio': 'Precio',
    'Tarifa Regular': 'Tarifa Regular',
    'Notas': 'Notas',
    'Se te cargará': 'Se te cargará ',
    'por el resto del período de facturación actual': 'por el resto del período de facturación actual',
    'y la tarifa estádar': 'y la tarifa estádar',
    'en futuros períodos': 'en futuros períodos',
    'Tu plan actual continuará activo por el resto del período de facturación actual. Se te cargará': 'Tu plan actual continuará activo por el resto del período de facturación actual. Se te cargará',
    'en el próximo período de facturación': 'en el próximo período de facturación',
    'y por el resto de períodos.': 'y por el resto de períodos.',
    'Nota': 'Nota',
    'El coste del bono se cargará en tu cuenta': 'El coste del bono se cargará en tu cuenta',
    'y se añadirá a tu cuota en los siguientes períodos de facturación': 'y se añadirá a tu cuota en los siguientes períodos de facturación',
    'Total a Pagar': 'Total a Pagar',
    'Selecciona tu tarjeta': 'Selecciona tu tarjeta',
    'Añadir nueva tarjeta': 'Añadir nueva tarjeta',
    '¡Se han acabado las firmas!': '¡Se han acabado las firmas!',
    '¡No tienes ningún plan activo!': '¡No tienes ningún plan activo!',
    'Puedes renovar o mejorar tu plan para continuar disfrutando de Fingerink': 'Puedes renovar o mejorar tu plan para continuar disfrutando de Fingerink',
    'Compra un plan ahora y sigue disfrutando de Fingerink': 'Compra un plan ahora y sigue disfrutando de Fingerink',
    'Actual': 'Actual',
    '¡Email Confirmado!': '¡Email Confirmado!',
    'Haciendo clic en Guardar, aceptas y entiendes que esta la representación legal de tu firma.': 'Haciendo clic en Guardar, aceptas y entiendes que esta la representación legal de tu firma.',
    'Guardar': 'Guardar',
    'Cancelar ': 'Cancelar ',
    'Limpiar': 'Limpiar',
    'Cancelar': 'Cancelar',
    'Selecciona tu sello': 'Selecciona tu sello',
    'Circular': 'Circular',
    'Cuadrado': 'Cuadrado',
    'Facturación': 'Facturación',
    'Próxima Factura': 'Próxima Factura',
    'No hay próximos pedidos': 'No hay próximos pedidos',
    'Servicios Restantes': 'Servicios Restantes',
    'Mejorar Plan': 'Mejorar Plan',
    'Seleccionar documento': 'Seleccionar documento',
    'Crear documento': 'Crear documento',
    'Usar plantilla': 'Usar plantilla',
    'Subiendo documento': 'Subiendo documento',
    'Editor documentos': 'Editor documentos',
    'No hay plantillas': 'No hay plantillas',
    'creadas': 'creadas',
    'Seleccionar': 'Seleccionar',
    'Yo': 'Yo',
    'Continuar': 'Continuar',
    'Firmantes': 'Firmantes',
    'Campos del documento': 'Campos del documento',
    'Información del Documento': 'Información del Documento',
    'El documento': 'El documento',
    'Creado el': 'Creado el',
    'Tipo de Firma': 'Tipo de Firma',
    'Estado': 'Estado',
    'Ver': 'Ver',
    'Original': 'Original',
    'Firmado': 'Firmado',
    'Tú': 'Tú',
    'Evidencias': 'Evidencias',
    'Asunto': 'Asunto:',
    'Firmante': 'Firmante',
    'Tipo': 'Tipo',
    'Todos': 'Todos',
    'Avanzada': 'Avanzada',
    'Presencial': 'Presencial',
    'Rápida': 'Rápida',
    'Completado': 'Completado',
    'Pendiente': 'Pendiente',
    'Esperando': 'Esperando',
    'Rechazado': 'Rechazado',
    'Desde la fecha': 'Desde la fecha',
    'Hasta la fecha': 'Hasta la fecha',
    'Enviar documento a firmar': 'Enviar documento a firmar',
    'Filtro': 'Filtro',
    'Nombre': 'Nombre',
    'Receptor': 'Receptor',
    'Fecha': 'Fecha',
    'Anterior': 'Anterior',
    'Siguiente': 'Siguiente',
    'No se han encontrado resultados con la búsqueda actual.': 'No se han encontrado resultados con la búsqueda actual.',
    'No se han creado firmas todavía': 'No se han creado firmas todavía',
    'Fingerink': 'Fingerink',
    'Hola! Firma Avanzada': 'Hola! Firma Avanzada',
    'texto_Avanzada_firmar': 'texto_Avanzada_firmar',
    'Ver documento': 'Ver documento',
    'DESCARGAR': 'DESCARGAR',
    'Hola! CORREO CERTIFICADO': 'Hola! CORREO CERTIFICADO',
    'Texto_burofax firmar': 'Texto_burofax firmar',
    'Ir a': 'Ir a',
    'Solución de Firma Electrónica': 'Solución de Firma Electrónica',
    'Iniciar Sesión': 'Iniciar Sesión',
    'Email': 'Email',
    'Contraseña': 'Contraseña',
    'Recuérdame': 'Recuérdame',
    '¿Has olvidado tu contraseña?': '¿Has olvidado tu contraseña?',
    'Cargando': 'Cargando',
    '¿No tienes cuenta?': '¿No tienes cuenta?',
    'Regístrate ahora': 'Regístrate ahora',
    'Introduce tu email para recuperar la contraseña': 'Introduce tu email para recuperar la contraseña.',
    'Recuperar Contraseña': 'Recuperar Contraseña',
    '¿Ya recuerdas tu contraseña?': '¿Ya recuerdas tu contraseña?',
    'Volver al Inicio de Sesión': 'Volver al Inicio de Sesión',
    'Mis Notificaciones': 'Mis Notificaciones',
    'Calendario con las acciones realizadas en la plataforma': 'Calendario con las acciones realizadas en la plataforma',
    'Detalles del Día': 'Detalles del Día',
    'Contenido del Día': 'Contenido del Día',
    'Todavía no hay notificaciones': 'Todavía no hay notificaciones',
    'Recuperar la contraseña': 'Recuperar la contraseña',
    'Cambiar Contraseña': 'Cambiar Contraseña',
    'Regístrate Ahora': 'Regístrate Ahora',
    'Nueva Plantilla': 'Nueva Plantilla',
    'No hay plantillas creadas todavía.': 'No hay plantillas creadas todavía.',
    'Editor de texto': 'Editor de texto',
    'Añadir Tarjeta': 'Añadir Tarjeta',
    'Opciones': 'Opciones',
    'Eliminar': 'Eliminar',
    'Actualizar Datos': 'Actualizar Datos',
    'Nombre de Empresa': 'Nombre de Empresa',
    'Web de la Empresa': 'Web de la Empresa',
    'NIF / CIF': 'NIF / CIF',
    'Dirección': 'Dirección',
    'Provincia': 'Provincia',
    'CP': 'CP',
    'Ciudad': 'Ciudad',
    'País': 'País',
    'Logo corporativo de tu empresa': 'Logo corporativo de tu empresa',
    'Subir Logo': 'Subir Logo',
    'Configuración': 'Configuración',
    'Equipo': 'Equipo',
    'Añadir Subcuenta': 'Añadir Subcuenta',
    'Aquí puedes gestionar las subcuentas de tu empresa': 'Aquí puedes gestionar las subcuentas de tu empresa',
    'Acción': 'Acción',
    'ID': 'ID',
    'Expiración': 'Expiración',
    'Pagado': 'Pagado',
    'Mis Servicios': 'Mis Servicios',
    'Comprar bonos y planes': 'Comprar bonos y planes',
    'Aquí aparecen los servicios contratados a Fingerink': 'Aquí aparecen los servicios contratados a Fingerink',
    'gastadas': 'gastadas',
    'Bonos contratados': 'Bonos contratados',
    'Programar envíos': 'Programar envíos',
    'Importar Archivos': 'Importar Archivos',
    'Exportar Archivos': 'Exportar Archivos',
    'Envío por Lotes': 'Envío por Lotes',
    'Próximos pedidos': 'Próximos pedidos',
    '¡No hay próximos pedidos!': '¡No hay próximos pedidos!',
    'Pedido': 'Pedido',
    'Cambiar Firma': 'Cambiar Firma',
    'Sello': 'Sello',
    'Cambiar Sello': 'Cambiar Sello',
    'Subcuentas': 'Subcuentas',
    'Añadir': 'Añadir',
    'tarjeta': 'tarjeta',
    'Comprueba que la información sea correcta y rellena la forma de pago': 'Comprueba que la información sea correcta y rellena la forma de pago',
    'Subscripción': 'Subscripción',
    'Mensual': 'Mensual',
    'Anual': 'Anual',
    'Precio total': 'Precio total',
    'Precio por mes': 'Precio por mes',
    'Al introducir la tarjeta no se cobrará nada en este momento.': 'Al introducir la tarjeta no se cobrará nada en este momento.',
    'Volver al paso anterior': 'Volver al paso anterior',
    'Primero, rellena la información del usuario': 'Primero, rellena la información del usuario',
    'First Name': 'First Name',
    'Last Name': 'Last Name',
    'Company Name': 'Company Name',
    'Nif/Cif': 'Nif/Cif',
    'Nif/Cif/Iva': 'Nif/Cif/Iva',
    'Password': 'Password',
    'Repeat password': 'Repeat password',
    'Haciendo clic en el botón de arriba aceptas los': 'Haciendo clic en el botón de arriba aceptas los',
    'Términos y Condiciones': 'Términos y Condiciones',
    'y la': 'y la',
    'Política de Privacidad': 'Política de Privacidad',
    '¿Ya tienes cuenta?': '¿Ya tienes cuenta?',
    'Registro Fingerink': 'Registro Fingerink',
    'A': 'A',
    'Firmas Enviadas': 'Firmas Enviadas',
    'Documentos Firmados ': 'Documentos Firmados ',
    'Firmas Pendientes': 'Firmas Pendientes',
    'Firmas Rechazadas': 'Firmas Rechazadas',
    'Estado Actual': 'Estado Actual',
    'Firmas por el tiempo': 'Firmas por el tiempo',
    'Completadas': 'Completadas',
    'Pendientes': 'Pendientes',
    'Rechazadas': 'Rechazadas',
    'Expiradas': 'Expiradas',
    'Pelea de usuarios': 'Pelea de usuarios',
    'Firmas completadas': 'Firmas completadas',
    'Información de las Firmas': 'Información de las Firmas',
    'Fill in signatories data': 'Fill in signatories data',
    'Un firmante por documento': 'Un firmante por documento',
    'Múltiples firmantes por documento': 'Múltiples firmantes por documento',
    'Rol': 'Rol',
    'Contactos': 'Contactos',
    'Añadir Firmante': 'Añadir Firmante',
    'Opciones de Envío': 'Opciones de Envío',
    'Mensaje para los Firmantes': 'Mensaje para los Firmantes:',
    'Enviar una copia cuando se complete': 'Enviar una copia cuando se complete:',
    'Enviar el documento a los firmantes el día': 'Enviar el documento a los firmantes el día:',
    'Fecha límite para firmar': 'Fecha límite para firmar:',
    'Añadir campos de': 'Añadir campos de',
    'Mantén presionado para añadir un campo': 'Mantén presionado para añadir un campo',
    'Si es necesario, añade texto al documento para completarlo': 'Si es necesario, añade texto al documento para completarlo',
    'Añade los campos que los firmantes deben rellenar': 'Añade los campos que los firmantes deben rellenar',
    'Firmar y completar el documento': 'Firmar y completar el documento',
    'Edición del Documento': 'Edición del Documento',
    'Campos de los Firmantes': 'Campos de los Firmantes',
    'Añade la información que necesites': 'Añade la información que necesites',
    'Mi información': 'Mi información',
    'Requerir información al firmante': 'Requerir información al firmante',
    'Turno de': 'Turno de',
    'para rellenar los campos': 'para rellenar los campos',
    'Este documento ha sido visto': 'Este documento ha sido visto',
    'Pulsa el botón "Firmar" para completar el proceso':'Pulsa el botón "Firmar" para completar el proceso',
    'Campos a rellenar': 'Campos a rellenar',
    'Estos son los campos que debes rellenar. Haz clic en ellos para verlos': 'Estos son los campos que debes rellenar. Haz clic en ellos para verlos',
    'Subir Documento': 'Subir Documento',
    'Select document': 'Select document',
    'SIGN TYPE': 'SIGN TYPE',
    'Selecciona el tipo de firma que quieres enviar': 'Selecciona el tipo de firma que quieres enviar',
    'Enviar': 'Enviar',
    'Nueva Incidencia': 'Nueva Incidencia',
    'Selecciona un departamento, proporciona un asunto al incidente y detalla lo ocurrido': 'Selecciona un departamento, proporciona un asunto al incidente y detalla lo ocurrido',
    'Selecciona el departamento encargado': 'Selecciona el departamento encargado',
    'Mis Incidencias': 'Mis Incidencias',
    'Lista con tus incidencias y sus estados': 'Lista con tus incidencias y sus estados',
    'Acciones': 'Acciones',
    'Ver Incidencia': 'Ver Incidencia',
    'No se han enviado incidencias todavía': 'No se han enviado incidencias todavía',









    '¿Comprar?': '¿Comprar?',
    'Se va a proceder con el pago y finalizar el proceso de compra': 'Se va a proceder con el pago y finalizar el proceso de compra',
    '¡Correcto!': '¡Correcto!',
    'Se ha comprado el producto correctamente': 'Se ha comprado el producto correctamente',
    'Algo ha ido mal': 'Algo ha ido mal',
    'Ha habido un error en el proceso de compra': 'Ha habido un error en el proceso de compra',
    'Email confirmado': 'Email confirmado',
    'No se ha podido confirmar el email': 'No se ha podido confirmar el email',
    'Firma cambiada': 'Firma cambiada',
    'Se ha modificado su firma correctamente': 'Se ha modificado su firma correctamente',
    'Sello cambiado': 'Sello cambiado',
    'Se ha modificado su sello correctamente': 'Se ha modificado su sello correctamente',
    'Ha habido un error intentando subir el documento, por favor, inténtelo de nuevo más adelante': 'Ha habido un error intentando subir el documento, por favor, inténtelo de nuevo más adelante',
    'Arrastra tu documento aquí para empezar el proceso de firma (.pdf, .docx, .doc, .png, .jp': 'Arrastra tu documento aquí para empezar el proceso de firma (.pdf, .docx, .doc, .png, .jp',
    'DROPS OR CLICK HERE TO REPLACE DOCUMENT': 'DROPS OR CLICK HERE TO REPLACE DOCUMENT',
    'REMOVE': 'REMOVE',
    'Ha ocurrido algún error. Inténtelo de nuevo.': 'Ha ocurrido algún error. Inténtelo de nuevo.',
    'Rechazar': 'Rechazar',
    'Una vez rechazado, no se podrá volver a firmar ni editar.': 'Una vez rechazado, no se podrá volver a firmar ni editar.',
    'Documento rechazado': 'Documento rechazado',
    'Se ha rechazado el documento correctamente': 'Se ha rechazado el documento correctamente',
    'Ha habido un error rechazando el documento': 'Ha habido un error rechazando el documento',
    'Esta firma ya no está disponible': 'Esta firma ya no está disponible',
    'Faltan campos por rellenar': 'Faltan campos por rellenar',
    'Rellena todos los campos para poder terminar el proceso de firma': 'Rellena todos los campos para poder terminar el proceso de firma',
    'Firmar documento': 'Firmar documento',
    'Una vez firmado, no se podrá volver a firmar ni editar.': 'Una vez firmado, no se podrá volver a firmar ni editar.',
    'Documento Firmado': 'Documento Firmado',
    'Has firmado el documento correctamente': 'Has firmado el documento correctamente',
    'Error firmando el documento': 'Error firmando el documento',
    'No se ha podido firmar el documento': 'No se ha podido firmar el documento',
    'Confirma tu correo': 'Confirma tu correo',
    'Este correo no está confirmado, por favor, revisa tu bandeja de entrada y confirma tu dirección de correo': 'Este correo no está confirmado, por favor, revisa tu bandeja de entrada y confirma tu dirección de correo',
    '¡Usuario o contraseña incorrectos!': '¡Usuario o contraseña incorrectos!',
    '¡Correo enviado!': '¡Correo enviado!',
    'Te hemos mandado los pasos a seguir para cambiar la contraseña en un correo electrónico.': 'Te hemos mandado los pasos a seguir para cambiar la contraseña en un correo electrónico.',
    'No hemos encontrado ningún usuario registrado con este correo electrónico.': 'No hemos encontrado ningún usuario registrado con este correo electrónico.',
    'La contraseña se ha cambiado correctamente.': 'La contraseña se ha cambiado correctamente.',
    'Hay un error con esta solicitud, por favor, dirígete al login e intenta restablecer la contraseña de nuevo.': 'Hay un error con esta solicitud, por favor, dirígete al login e intenta restablecer la contraseña de nuevo.',
    'Las contraseñas no coinciden': 'Las contraseñas no coinciden',
    'Eliminar documento': 'Eliminar documento',
    'El documento se enviará a la papelera. ¿Estás seguro de que quieres realizar esta acción?': 'El documento se enviará a la papelera. ¿Estás seguro de que quieres realizar esta acción?',
    'Se ha eliminado el documento correctamente': 'Se ha eliminado el documento correctamente',
    'Algo ha ido mal eliminando el documento': 'Algo ha ido mal eliminando el documento',
    'El documento "': 'El documento "',
    ' se enviará a la papelera. ¿Estás seguro de que quieres realizar esta acción?': '" se enviará a la papelera. ¿Estás seguro de que quieres realizar esta acción?',
    'No tienes plantillas disponibles': 'No tienes plantillas disponibles',
    'No te quedan plantillas, por favor contrata un plan superior o un bono con plantillas': 'No te quedan plantillas, por favor contrata un plan superior o un bono con plantillas',
    'Ir a plantillas': 'Ir a plantillas',
    'Guardar plantilla': 'Guardar plantilla',
    'Se ha guardado la plantilla correctamente': 'Se ha guardado la plantilla correctamente',
    'Ha habido un error intentando crear la plantilla, por favor, inténtelo de nuevo más adelante': 'Ha habido un error intentando crear la plantilla, por favor, inténtelo de nuevo más adelante',
    '¿Estás seguro que quieres salir del proceso de firma? Se pueden perder datos': '¿Estás seguro que quieres salir del proceso de firma? Se pueden perder datos',
    'Tarjeta añadida': 'Tarjeta añadida',
    '"La tarjeta se ha añadido correctamente': '"La tarjeta se ha añadido correctamente',
    'Error añadiendo tarjeta': 'Error añadiendo tarjeta',
    'Esta tarjeta no se ha podido añadir. Por favor, inténtelo de nuevo más adelante': 'Esta tarjeta no se ha podido añadir. Por favor, inténtelo de nuevo más adelante',
    '¿Estás seguro?': '¿Estás seguro?',
    'Al guardar la empresa los datos anteriores se perderán': 'Al guardar la empresa los datos anteriores se perderán',
    'Se han editado los datos de usuario para que los cambios sean visibles, por favor, recarga la página.': 'Se han editado los datos de usuario para que los cambios sean visibles, por favor, recarga la página.',
    'Ha habido un error actualizando los datos de la empresa': 'Ha habido un error actualizando los datos de la empresa',
    'Al guardar el usuario los datos anteriores se perderán': 'Al guardar el usuario los datos anteriores se perderán',
    'Se han editado los datos del usuario': 'Se han editado los datos del usuario',
    'Ha habido un error actualizando los datos del usuario': 'Ha habido un error actualizando los datos del usuario',
    'Al deshabilitar el usuario no podrá tener acceso a la aplicación': 'Al deshabilitar el usuario no podrá tener acceso a la aplicación',
    'Se ha deshabilitado al usuario': 'Se ha deshabilitado al usuario',
    'Ha habido un error deshabilitando al usuario': 'Ha habido un error deshabilitando al usuario',
    'No te quedan usuarios': 'No te quedan usuarios',
    'No puedes crear más usuarios, cambia tu plan o añade un bono de usuarios': 'No puedes crear más usuarios, cambia tu plan o añade un bono de usuarios',
    'Vas a crear un nuevo usuario que podrá acceder a la aplicación y enviar documentos para firmar': 'Vas a crear un nuevo usuario que podrá acceder a la aplicación y enviar documentos para firmar',
    'Se ha creado el nuevo usuario': 'Se ha creado el nuevo usuario',
    'Ha habido un error creando al usuario': 'Ha habido un error creando al usuario',
    'Al habilitar el usuario podrá tener acceso a la aplicación': 'Al habilitar el usuario podrá tener acceso a la aplicación',
    'Se ha habilitado al usuario': 'Se ha habilitado al usuario',
    'Ha habido un error habilitando al usuario': 'Ha habido un error habilitando al usuario',
    '¿Cancelar pedido?': '¿Cancelar pedido?',
    'Se va a cancelar el pedido': 'Se va a cancelar el pedido',
    'Se ha cancelado correctamente': 'Se ha cancelado correctamente',
    'Ha habido un error cancelando el pedido': 'Ha habido un error cancelando el pedido',
    'Vas a cambiar tu contraseña de acceso a la aplicación, la anterior ya no funcionará.': 'Vas a cambiar tu contraseña de acceso a la aplicación, la anterior ya no funcionará.',
    '¡Se ha cambiado la contraseña!': '¡Se ha cambiado la contraseña!',
    'Ha habido un error actualizando la contraseña': 'Ha habido un error actualizando la contraseña',
    'La repetición de la contraseña no coincide con la nueva contraseña': 'La repetición de la contraseña no coincide con la nueva contraseña',
    'Es necesario rellenar el email y el nombre como mínimo': 'Es necesario rellenar el email y el nombre como mínimo',
    'Se ha cambiado la tarjeta por defecto a esta': 'Se ha cambiado la tarjeta por defecto a esta',
    'No se ha podido cambiar la tarjeta por defecto': 'No se ha podido cambiar la tarjeta por defecto',
    '¿Eliminar?': '¿Eliminar?',
    'Se va a proceder a eliminar la tarjeta': 'Se va a proceder a eliminar la tarjeta',
    'Tarjeta eliminada': 'Tarjeta eliminada',
    'Se ha eliminado la tarjeta correctamente': 'Se ha eliminado la tarjeta correctamente',
    'No se ha podido eliminar la tarjeta': 'No se ha podido eliminar la tarjeta',
    'Se ha creado el usuario, por favor, revisa tu bandeja de entrada para confirmar el correo electrónico y poder acceder.': 'Se ha creado el usuario, por favor, revisa tu bandeja de entrada para confirmar el correo electrónico y poder acceder.',
    'No se ha podido realizar el registro correctamente': 'No se ha podido realizar el registro correctamente',
    'Cada firmante firmará su documento de forma independiente': 'Cada firmante firmará su documento de forma independiente',
    'Todos los firmantes firmarán en el mismo documento': 'Todos los firmantes firmarán en el mismo documento',
    'Paso 1': 'Paso 1',
    'Si es necesario, añade texto al documento': 'Si es necesario, añade texto al documento',
    'Cerrar': 'Cerrar',
    'Paso': 'Paso',
    'Arrastra los campos que deba rellenar': 'Arrastra los campos que deba rellenar',
    'Step ': 'Step ',
    'Texto': 'Texto',
    'Firma': 'Firma',
    'Draw signature': 'Draw signature',
    'Mi nombre': 'Mi nombre',
    'Mis apellidos': 'Mis apellidos',
    'Mi email': 'Mi email',
    'Mi empresa': 'Mi empresa',
    'Dirección de empresa': 'Dirección de empresa',
    'Mi teléfono': 'Mi teléfono',
    'Mi ID / Tax Id / VAT number': 'Mi ID / Tax Id / VAT number',
    'Adjuntar archivo': 'Adjuntar archivo',
    'Área de texto': 'Área de texto',
    'Apellidos': 'Apellidos',
    'Empresa': 'Empresa',
    'Teléfono': 'Teléfono',
    'Cantidad': 'Cantidad',
    'ID / Tax Id / VAT número': 'ID / Tax Id / VAT número',
    'Logo': 'Logo',
    'Mi firma': 'Mi firma',
    'Hay campos sin rellenar': 'Hay campos sin rellenar',
    'Por favor, rellena todos los campos para poder completar la firma.': 'Por favor, rellena todos los campos para poder completar la firma.',
    'ARRASTRA TUS DOCUMENTOS AQUÍ PARA EMPEZAR EL PROCESO DE FIRMA': 'ARRASTRA TUS DOCUMENTOS AQUÍ PARA EMPEZAR EL PROCESO DE FIRMA',
    'Firma en un click': 'Firma en un click',
    'El documento se firma mediante un botón, sin necesidad de nada más': 'El documento se firma mediante un botón, sin necesidad de nada más',
    'Firma avanzada': 'Firma avanzada',
    'Firma en persona': 'Firma en persona',
    'El documento se firma en tu dispositivo': 'El documento se firma en tu dispositivo',
    'No tienes firmas disponibles': 'No tienes firmas disponibles',
    'No te quedan firmas para enviar, por favor contrata un plan superior o un bono con firmas': 'No te quedan firmas para enviar, por favor contrata un plan superior o un bono con firmas',
    'Ir a firmas enviadas': 'Ir a firmas enviadas',
    '¿Enviar a firmar este documento?': '¿Enviar a firmar este documento?',
    '¿Terminar el proceso de autofirma?': '¿Terminar el proceso de autofirma?',
    '¿Finalizar la firma presencial?': '¿Finalizar la firma presencial?',
    'Se ha enviado la firma correctamente': 'Se ha enviado la firma correctamente',
    'Se ha enviado la firma correctamente, ¿Deseas guardar el documento como plantilla?': 'Se ha enviado la firma correctamente, ¿Deseas guardar el documento como plantilla?',
    'Guardar como plantilla': 'Guardar como plantilla',
    'Ir a documentos': 'Ir a documentos',
    'Guardado': 'Guardado',
    'Ha habido un problema guardando el documento como plantilla': 'Ha habido un problema guardando el documento como plantilla',
    'La incidencia se ha creado correctamente': 'La incidencia se ha creado correctamente',
    'Ha habido un error creando la incidencia': 'Ha habido un error creando la incidencia',
    'Se ha creado la incidencia correctamente': 'Se ha creado la incidencia correctamente',
    'Detalla la incidencia': 'Detalla la incidencia',
    'El mensaje enviado está vacío': 'El mensaje enviado está vacío',
    'No se ha podido crear la incidencia correctamente': 'No se ha podido crear la incidencia correctamente',
    'Se va a subir el archivo': 'Se va a subir el archivo',
    'Archivo subido': 'Archivo subido',
    'El archivo se ha subido correctamente': 'El archivo se ha subido correctamente',
    'Ha habido un error intentando subir el archivo, por favor, inténtelo de nuevo más tarde': 'Ha habido un error intentando subir el archivo, por favor, inténtelo de nuevo más tarde',

    'Enviar a firmar': 'Enviar a firmar',
    'Se solicita a los firmantes trazar la firma en el documento': "Se solicita a los firmantes trazar la firma en el documento",





    ////////////////////////////////////////////////////////////////////
    //Nuevas 23 / 5 /18
    //////////////////////////////////////////////////////////////////
    'CIF/IVA': 'CIF/IVA',
    'Estoy dado de alta en el ROI': 'Estoy dado de alta en el ROI',
    'Repetir contraseña': 'Repetir contraseña',
    'Particular': 'Particular',
    'Precio por año': 'Precio por año',
    'IVA': 'IVA',
    'Acepto los': 'Acepto los',
    'Documento enviado': 'Documento enviado',
    'Documento completado': 'Documento completado',
    'Nuevo usuario': 'Nuevo usuario',
    '¿Reenviar correo?': '¿Reenviar correo?',
    'Se va a reenviar el correo al firmante': 'Se va a reenviar el correo al firmante',
    'El correo se ha enviado satisfactoriamente': 'El correo se ha enviado satisfactoriamente',
    'Ha habido un error enviando el correo': 'Ha habido un error enviando el correo',
    'Reenviar correo': 'Reenviar correo',
    'Cambiar de plan': 'Cambiar de plan',
    'Cambio de plan': 'Cambio de plan',
    'Costes': 'Costes',
    'más IVA': 'más IVA',
    'Mi Perfil': 'Mi Perfil',
    'Edita tu perfil': 'Edita tu perfil',
    'Fecha Registro': 'Fecha Registro',
    'Notificaciones por correo': 'Notificaciones por correo',
    'Habilitado': 'Habilitado',
    'Deshabilitado': 'Deshabilitado',
    'Recibir NewsLetter': 'Recibir NewsLetter',
    'Productos': 'Productos',
    'Total': 'Total',
    'Panel de configuración de tu cuenta en Fingerink': 'Panel de configuración de tu cuenta en Fingerink',
    'Mi Empresa': 'Mi Empresa',
    'Mis Tarjetas': 'Mis Tarjetas',
    'Mis Facturas': 'Mis Facturas',
    'Mi Agenda': 'Mi Agenda',
    'Cuentas API': 'Cuentas API',
    'Aquí aparece toda la información sobre tu empresa': 'Aquí aparece toda la información sobre tu empresa',
    'Aquí aparecen las tarjetas que podrás utilizar para pagar tus servicios': 'Aquí aparecen las tarjetas que podrás utilizar para pagar tus servicios',
    'Tarjeta con la que se realizarán los pagos': 'Tarjeta con la que se realizarán los pagos',
    'Otras tarjetas': 'Otras tarjetas',
    'Aquí se muestra tu facturación': 'Aquí se muestra tu facturación',
    'Añadir Cuenta': 'Añadir Cuenta',
    'Añadir Cuenta API': 'Añadir Cuenta API',
    'Aquí pudes gestionar las subcuentas de tu empresa': 'Aquí pudes gestionar las subcuentas de tu empresa',
    'No hay cuentas API creadas': 'No hay cuentas API creadas',
    'Ver API-KEY': 'Ver API-KEY',
    'Las contraseñas introducidas no coinciden': 'Las contraseñas introducidas no coinciden',
    'La cuenta ha sido creada. Puedes consultar ya tu clave secreta': 'La cuenta ha sido creada. Puedes consultar ya tu clave secreta',
    'Vas a cambiar tu contraseña de la cuenta de la API, la anterior ya no funcionará.': 'Vas a cambiar tu contraseña de la cuenta de la API, la anterior ya no funcionará.',
    'La contraseña ha sido modificada': 'La contraseña ha sido modificada',
    'Al borrar esta cuenta dejarás de poder usar la api con estos accesos': 'Al borrar esta cuenta dejarás de poder usar la api con estos accesos',
    'La cuenta ha sido eliminada': 'La cuenta ha sido eliminada',
    'Crear Cuenta': 'Crear Cuenta',
    'Repite contraseña': 'Repite contraseña',
    'Contraseña Actual': 'Contraseña Actual',
    'Nueva contraseña': 'Nueva contraseña',
    'Accesos API': 'Accesos API',
    'API-KEY': 'API-KEY',
    'Aquí puedes apuntar tus contactos para recordarlos en tus envíos': 'Aquí puedes apuntar tus contactos para recordarlos en tus envíos',
    'No hay contactos': 'No hay contactos',
    'Gestionar Grupos': 'Gestionar Grupos',
    'Se va a borrar este contacto, esta acción no se puede deshacer': 'Se va a borrar este contacto, esta acción no se puede deshacer',
    'Se ha eliminado el contacto': 'Se ha eliminado el contacto',
    'Se ha añadido el usuario a la agenda y al grupo seleccionado': 'Se ha añadido el usuario a la agenda y al grupo seleccionado',
    'Se ha añadido el usuario a la agenda': 'Se ha añadido el usuario a la agenda',
    'Grupos': 'Grupos',
    'Crear Grupo': 'Crear Grupo',
    'Aquí puedes organizar tus contactos en grupos pudiendo simplificar tus envíos masivos': 'Aquí puedes organizar tus contactos en grupos pudiendo simplificar tus envíos masivos',
    'Editar': 'Editar',
    'No hay grupos creados': 'No hay grupos creados',
    'Grupo creado correctamente': 'Grupo creado correctamente',
    'Se va a borrar este grupo, esta acción no se puede deshacer': 'Se va a borrar este grupo, esta acción no se puede deshacer',
    'Se ha eliminado el grupo': 'Se ha eliminado el grupo',
    'Se han editado los usuarios de este grupo': 'Se han editado los usuarios de este grupo',
    'Guardar Cambios': 'Guardar Cambios',
    'Aquí podrás añadir o quitar miembros a un grupo': 'Aquí podrás añadir o quitar miembros a un grupo',
    'Editar Grupo': 'Editar Grupo',
    'Seleccionado': 'Seleccionado',
    'Añadir contacto': 'Añadir contacto',
    'Url web': 'Url web',
    'Dirección (Línea 1)': 'Dirección (Línea 1)',
    'Dirección (Línea 2)': 'Dirección (Línea 2)',
    'Grupo agenda': 'Grupo agenda',
    'ha solicitado tu firma en el siguiente documento a través de Fingerink': 'ha solicitado tu firma en el siguiente documento a través de Fingerink',



    //Nuevas 24/5/18

    'Buscar por asunto': 'Buscar por asunto',
    'Finalizar': 'Finalizar',
    'Nombre de la plantilla': 'Nombre de la plantilla',
    'Nombre del Grupo': 'Nombre del Grupo',
    'Campo': 'Campo',
    'Asunto del mensaje que se envia a destinatario y copias': 'Asunto del mensaje que se envia a destinatario y copias',
    'Texto que aparecerá en el correo': 'Texto que aparecerá en el correo',
    'Fecha de envío': 'Fecha de envío',
    'Caducidad': 'Caducidad',
    'Motivo de la incidencia': 'Motivo de la incidencia',
    '¿Cómo Funciona?': '¿Cómo Funciona?',
    'Documento creado': 'Documento Creado',


    //Nuevas 28/5/18
    '¡Tu empresa no tiene suficientes usuarios contratados!': '¡Tu empresa no tiene suficientes usuarios contratados!',
    'Por favor, contacte con el administrador de su empresa para que contrate un plan superior o deshabilite usuarios': 'Por favor, contacte con el administrador de su empresa para que contrate un plan superior o deshabilite usuarios',

    'Tienes más plantillas de las contratadas': 'Tienes más plantillas de las contratadas',
    'Para usar las plantillas, borre plantillas o contrata un plan superior': 'Para usar las plantillas, borre plantillas o contrata un plan superior',
    'Crear Documento': 'Crear documento',



    //Nuevas 28/5/18
    "Firma Creada": 'Firma Creada',
    "Correo enviado": 'Correo enviado',
    "Email abierto": 'Email abierto',
    "Documento abierto": 'Documento abierto',
    "Términos y condiciones aceptados": 'Términos y condiciones aceptados',
    "Firma vista": 'Firma vista',
    "Documento firmado": 'Documento firmado',


    'Error': 'Error',
    'Borrador': 'Borrador',
    'Esperando a enviar': 'Esperando a enviar',
    'Expirado': 'Expirado',
    'Ver detalles': 'Ver detalles',
    'Se ha añadido la tarjeta correctamente': 'Se ha añadido la tarjeta correctamente',

    'Total a pagar': 'Total a pagar',
    'Ver próximos pedidos': 'Ver próximos pedidos',
    'Elige tu firma': 'Elige tu firma',
    'Sube tu firma': 'Sube tu firma',
    'Añadir firma': 'Añadir firma',
    'Arrastra aquí o haz click para reemplazar': 'Arrastra aquí o haz click para reemplazar',
    'Arrastra aquí el documento o haz click': 'Arrastra aquí el documento o haz click',
    'Firma aquí': 'Firma aquí',
    'Estadísticas': 'Estadísticas',
    'Enviar documento': 'Enviar documento',
    'Mejora tu plan o añade firmas': 'Mejora tu plan o añade firmas',
    'Enviadas': 'Enviadas',
    'Documentos restantes': 'Documentos restantes',
    'Enviados': 'Emails enviados',
    'Completados': 'Emails vistos',
    'Dispositivo': 'Dispositivo',
    'Tiempo': 'Tiempo',
    'Tu firma': 'Tu firma',
    'Tu logo': 'Tu logo',
    'Tu sello': 'Tu sello',
    'Esperando firma': 'Esperando',
    'Estadísticas usuarios': 'Estadísticas usuarios',
    'Envío': 'Envío',




    '¡Usuario deshabilidado!': '¡Usuario deshabilidado!',
    'Por favor, contacte con el administrador de su empresa': 'Por favor, contacte con el administrador de su empresa',
    'Aceptar': 'Aceptar',

    'días': 'días',
    'Mi cuenta': 'Mi cuenta',
    'Cambiar': 'Cambiar',
    'Proceder al pago': 'Proceder al pago',
    'y': 'y',

    'Emails Enviados': 'Emails Enviados',
    'Emails Sin Abrir': 'Emails Sin Abrir',
    'Emails Abiertos': 'Emails Abiertos',
    'Selecciona plantilla': 'Selecciona plantilla',
    'Quiero emails certificados': 'Obtener emails certificados',
    'Actividad': 'Actividad',


    'Dibuja tu firma': 'Dibuja tu firma',
    'Firmar': 'Firmar',
    'Usuarios por documento': 'Usuarios por documento',
    'Equipo de trabajo': 'Equipo de trabajo',
    'Introducir tarjeta más adelante': 'Introducir tarjeta más adelante',
    'Subir documento': 'Subir documento',
    'Adjuntar imagen': 'Adjuntar imagen',
    'Comprar bono': 'Comprar bono',
    'Cancelar plan actual y comprar el nuevo plan': 'Cancelar plan actual y comprar el nuevo plan',
    'Cambiar el plan en el próximo período de facturación': 'Cambiar el plan en el próximo período de facturación',

    'Editar documento': 'Editar documento',

    'Cuenta':'Cuenta',
    'Suscripción':'Suscripción',
    'Gestión de grupos de contactos':'Gestión de grupos de contactos',
    'Atrás':'Atrás',
    'Subir logo':'Subir logo',
    'Seleccionar sello':'Seleccionar sello',
    'Cambiar contraseña':'Cambiar contraseña',
    'Sello corporativo de tu empresa':'Sello corporativo de tu empresa',
    '¿Es Particular?':'¿Es Particular?',
    '¿Pertenece ROI?':'¿Pertenece ROI?',
    'Descargar':'Descargar',
    'Crear Grupo de Agenda': 'Crear grupo en agenda',
    'Seleccionar todos': 'Seleccionar todos',
    'Insertar Firmantes': 'Insertar Firmantes',
    'Hay firmantes sin campo de firma': 'Hay firmantes sin campo de firma',
    'Inserta al menos un campo de firma a cada firmante para poder continuar': 'Inserta al menos un campo de firma a cada firmante para poder continuar',

    'Hay firmantes que no tienen campo de firma. ¿Quieres continuar de todas formas?': 'Hay firmantes que no tienen campo de firma. ¿Quieres continuar de todas formas?',
    'Editar y enviar':'Editar y enviar',
    'Editar y guardar':'Editar y guardar',
    'Añadir contactos':'Añadir contactos',
    'Documento visto':'Documento visto',
    'Documento':'Documento',
    'Archivo Adjunto':'Archivo Adjunto',
    '¡Login incorrecto!':'Login incorrecto!',
    'Nombre de usuario o contraseña incorrectos':'Nombre de usuario o contraseña incorrectos',

    '¡Usuario ya registrado!':'¡Usuario ya registrado!',
    'El email introducido ya tiene una cuenta de Fingerink asociada':'El email introducido ya tiene una cuenta de Fingerink asociad',
    'Ha habido un error durante el registro':'Ha habido un error durante el registro',
    'Al eliminar el usuario no podrá tener acceso a la aplicación, los documentos enviados pasarán a pertenecer al administrador':'Al eliminar el usuario no podrá tener acceso a la aplicación, los documentos enviados pasarán a pertenecer al administrador',
    'Se ha eliminado al usuario':'Se ha eliminado al usuario',
    'Ha habido un error eliminando al usuario':'Ha habido un error eliminando al usuario',
    'Si':'Si',
    'No':'No',
    'Elige un paso':'Elige un paso',
    
    'Paso 1. Añadir mis datos':'Paso 1. Añadir mis datos',
    'Quiero añadir mi firma y mis datos antes de ir al paso 2':'Quiero añadir mi firma y mis datos antes de ir al paso 2',
    'Paso 2. Solicitar datos al firmante':'Paso 2. Solicitar datos al firmante',
    'Solo quiero añadir los campos que voy a solicitar a mis firmantes':'Solo quiero añadir los campos que voy a solicitar a mis firmantes',
    
    
    
    
    'Recuerda: aunque introduzcas la tarjeta ahora, tienes 15 días para probar la plataforma y cancelar tu cuenta cuando quieras':'Recuerda: aunque introduzcas la tarjeta ahora, tienes 15 días para probar la plataforma y cancelar tu cuenta cuando quieras',
    
    'Email (Se enviarán los datos de acceso a esta dirección)': 'Email (Se enviarán los datos de acceso a esta dirección)',
    'Añadir categoría':'Añadir categoría',
    'es tu turno de firma':'es tu turno de firma',
    'Por favor, rellena los campos que':'Por favor, rellena los campos que',
    'te ha solicitado':'te ha solicitado',
    
    'Acepta los Términos y Condiciones y la Política de Privacidad':'Acepta los Términos y Condiciones y la Política de Privacidad',
    'Haz click en Ver documento':'Haz click en Ver documento',
    'Haz click en Descargar para guardar una copia del documento':'Haz click en Descargar para guardar una copia del documento',
    'Revisa el documento y haz click en Firmar para completar el proceso de firma':'Revisa el documento y haz click en Firmar para completar el proceso de firma',
    'Revisa y firma el documento en la pantalla de tu dispositivo':'Revisa y firma el documento en la pantalla de tu dispositivo',
    'Haz click en Firmar para completar el proceso de firma':'Haz click en Firmar para completar el proceso de firma',
    
    
    /*
        'Revisa el documento y presiona FINALIZAR cuando esté listo': 'Revisa el documento y presiona FINALIZAR cuando esté listo',
        'Firma en la pantalla de tu dispositivo': 'Firma en la pantalla de tu dispositivo',
        'Presiona ENVIAR para finalizar el proceso': 'Presiona ENVIAR para finalizar el proceso',
    */
    'Omitir':'Omitir',
    'Puedes empezar enviando un documento a firmar desde aquí':'Puedes empezar enviando un documento a firmar desde aquí',
    'Desde este apartado o desde el botón azul de la izquierda, envía documentos para que otros los firmen':'Desde este apartado o desde el botón azul "Enviar a firmar" de la izquierda, envía documentos para que otros los firmen',
    'Aquí puedes personalizar tu firma. La añadirás a los documentos que quieras firmar':'Aquí puedes personalizar tu firma. La añadirás a los documentos que quieras firmar',
    'Añade el sello de tu empresa o tu sello personal para agregarlo a tus documentos':'Añade el sello de tu empresa o tu sello personal para agregarlo a tus documentos',
    'Sube un logo para personalizar tus documentos con tu marca':'Sube un logo para personalizar tus documentos con tu marca',
    'Email certificado: con esta opción puedes enviar documentos que necesites que el destinatario reciba y vea':'Email certificado: con esta opción puedes enviar documentos que necesites que el destinatario reciba y vea',
    'Firma presencial: Utiliza esta opción cuando necesites que tu acompañante firme un documento':'Firma presencial: Utiliza esta opción cuando necesites que tu acompañante firme un documento',
    'Autofirma: Elige esta opción cuando quieras firmar un documento tu mismo y eviarlo a alguien o descargarlo en tu dispositivo':'Autofirma: Elige esta opción cuando quieras firmar un documento tu mismo y eviarlo a alguien o descargarlo en tu dispositivo',
    
    'Arrastrar archivos o click aquí para subir':'Arrastrar archivos o click aquí para subir',
    'Ver actividad':'Ver actividad',
    'Quiero recibir Avisos y Ofertas de Fingerink':'Quiero recibir Avisos y Ofertas de Fingerink',
    'Vas a eliminar esta categoría. Las firmas de esta categoría serán movidas a Principal':'Vas a eliminar esta categoría. Las firmas de esta categoría serán movidas a Principal',
    'Mover Categoría':'Mover Categoría',
    'Mover a Principal':'Mover a Principal',
    
    'al mes':'al mes',
    
    
    'Por favor, confirma tu email y podrás empezar a firmar documentos':'Por favor, confirma tu email y podrás empezar a firmar documentos',
    'Tu período de prueba expira en':'Tu período de prueba expira en',
    'Compra tu plan':'Compra tu plan',
    
    // FIRMA FINALIZADA
    'Documento Firmado Correctamente':'Documento Firmado Correctamente',
    'Ya esta! Tu firma ha sido firmada electronicamente utilizando Fingerink':'Ya está! Tu firma ha sido firmada electrónicamente utilizando Fingerink',
    'Si sientes curiosidad sobre como funciona Fingerink, no dudes en pasarte por nuestra web':'Si sientes curiosidad sobre como funciona Fingerink, no dudes en pasarte por nuestra web',
    'Descubir Fingerink':'Descubir Fingerink',


    'Principal':'Principal',
    'Seleccionar perfil':'Seleccionar perfil',

    'Solicitar DNI':'Solicitar DNI',
    'Comenzó el':'Comenzó el',
    'Termina el':'FTermina el',
    'El firmante ha solicitado que verifiques tu identidad con tu DNI':'Se ha solicitado que verifiques tu identidad con tu DNI',
    'Haz una foto a la parte delantera de tu DNI':'Haz una foto a la parte delantera de tu DNI',
    '¿Es correcta la foto?' :'¿Es correcta la foto?' ,
    'Haz una foto a la parte trasera de tu DNI':'Haz una foto a la parte trasera de tu DNI',
    'Verificando identidad':'Verificando identidad',
    'Capturar':'Capturar',
    'Repetir':'Repetir',
    'Realizar la foto trasera':'Realizar la foto trasera',
    'Checkbox':'Marcar casilla',
    'Radiobutton':'Marcar casilla',
    'Mark with a cross':'Marcar casilla',
    'Click en la casilla para marcarla':'Click en la casilla para marcarla',
    'Click para seleccionar una fecha':'Click para seleccionar una fecha',
    'Click para firmar':'Click para firmar',
    'Adjunta un archivo':'Adjunta un archivo',

    'No te quedan créditos':'No te quedan créditos',
    'Subir archivo':'Subir archivo',
    'Color':'Color',
    'Actualizar al plan':'Actualizar al plan',
    'IVA no incluido':'IVA no incluido',
    'Si dispones de un CIF válido dentro de la unión europea no deberás pagar el IVA, excepto en el caso de ser una empresa Española, donde aplicamos el IVA correspondiente':'Si dispones de un CIF válido dentro de la unión europea no deberás pagar el IVA, excepto en el caso de ser una empresa Española, donde aplicamos el IVA correspondiente',
    'Licencias':'Licencias',
    'Periodos':'Periodos',
    'Cambiar plan actual y pagar en este momento':'Cambiar plan actual y pagar en este momento',
    'Cambiar el plan en el próximo periodo de facturación':'Cambiar el plan en el próximo periodo de facturación',
    'Cambiar plan':'Cambiar plan',
    'Continuar y pagar':'Continuar y pagar',
    'Al hacer click en continuar aceptas las condiciones legales':'Al hacer click en continuar aceptas las condiciones legales',
    'Primero rellena tus datos de facturación':'Primero rellena tus datos de facturación',
    'Enviar el documento individualmente a cada destinatario':'Enviar el documento individualmente a cada destinatario',
    'Enviar el mismo documento a todos los destinatarios':'Enviar el mismo documento a todos los destinatarios',
    'Selecciona tipo de firma o envío':'Selecciona tipo de firma o envío',
    'Correo Certificado':'Correo Certificado',
    'DNI firmantes':'DNI firmantes',
    'Nuevo documento':'Nuevo documento',
    'Inicio':'Inicio',
    'Simple':'Simple',
    'Certificado':'Certificado',
    'Organización':'Organización',
    'Método de pago':'Método de pago',
    'Mejorar':'Mejorar',
    'Créditos totales':'Créditos totales',
    'de':'de',
    'consumidos':'consumidos',
    'Nuestros planes y precios':'Nuestros planes y precios',
    'Elige el que más se adapte a tus necesidades':'Elige el que más se adapte a tus necesidades',
    'por licencia':'por licencia',
    'DAY':'dias',
    'MONTH':'al mes',
    'Firma electrónica':'Firma electrónica',
    'créditos':'créditos',
    'Hasta':'Hasta',
    'Nueva licencia':'Nueva licencia',
    'Añadir datos':'Añadir datos',
    'Componentes':'Componentes',
    'de Equipo':'de Equipo',
    'Deshabilitar':'Deshabilitar',
    'Desde este apartado o desde el botón verde de la izquierda, envía documentos para que otros los firmen':'Desde este apartado o desde el botón verde de la izquierda, envía documentos para que otros los firmen',
    'Empieza un proceso de firma ahora':'Empieza un proceso de firma ahora',
    'Envía un documento':'Envía un documento',
    'Últimos documentos firmados':'Últimos documentos firmados',
    'No hay documentos firmados todavía':'No hay documentos firmados todavía',
    'Draft':'Borrador',
    'Advanced':'Avanzada',
    'Fast':'Simple',
    'SelfSigning':'Autofirma',
    'InPerson':'En Persona',
    'Proceso':'Proceso',
    'PENDING':'Pendiente',
    'REFUSED':'Rechazado',
    'WAITING':'Esperando',
    'COMPLETED':'Completado',
    'SEND':'Enviado',
    'OPENSIGN':'Correo abierto',
    'ACCEPT':'Aceptación de términos y condiciones',
    'VIEW':'Documento visto',
    'SIGN':'Firmado',
    'Sin permisos':'Sin permisos',
    'Algún dato no es correcto':'Algún dato no es correcto',
    'Ha habido un error al eliminar la categoría':'Ha habido un error al eliminar la categoría',
    'Cambiar categoría':'Cambiar categoría',
    'Previsualizar':'Previsualizar',
    'Ir al documento':'Ir al documento',
    '¿Quieres que te reenviemos el correo de confirmación de email?':'¿Quieres que te reenviemos el correo de confirmación de email?',
    'Hemos enviado un email a tu dirección de correo electrónico para poder confirmar tu cuenta.':'Hemos enviado un email a tu dirección de correo electrónico para poder confirmar tu cuenta.',
    'Por favor, confirma tu email y empieza a firmar documentos':'Por favor, confirma tu email y empieza a firmar documentos',
    'Compra tu plan ahora':'Compra tu plan ahora',
    'Documento a enviar':'Documento a enviar',
    'Destinatarios y configuración de mensaje':'Destinatarios y configuración de mensaje',
    'Enviar el día':'Enviar el día',
    'Obligatorio':'Obligatorio',
    'Fondo blanco':'Fondo blanco',
    'Mi ID / Tax Id / VAT number")':'Mi ID / Tax Id / VAT number")',
    'Se ha comprado la licencia correctamente':'Se ha comprado la licencia correctamente',
    'Ha habido un problema al comprar la licencia':'Ha habido un problema al comprar la licencia',
    'Añade tantas licencias como necesites':'Añade tantas licencias como necesites',
    'Se va a realizar el cobro del siguiente importe':'Se va a realizar el cobro del siguiente importe',
    'IVA incluido':'IVA incluido',
    'Introduce los datos del nuevo usuario':'Introduce los datos del nuevo usuario',
    'Los usuarios recibirán una invitación de Fingerink a su email':'Los usuarios recibirán una invitación de Fingerink a su email',
    'En tu factura mensual se reflejarán las licencias añadidas':'En tu factura mensual se reflejarán las licencias añadidas',
    'sin IVA':'sin IVA',
    'Añadir la información':'Añadir la información',
    'Componentes de equipo':'Componentes de equipo',
    'Plantillas consumidas':'Plantillas consumidas',
    'Plantillas totales':'Plantillas totales',
    'Rellena la información del documento':'Rellena la información del documento',
    'Ha habido un problema al verificar tu identidad, por favor inténtelo de nuevo':'Ha habido un problema al verificar tu identidad, por favor inténtelo de nuevo',
    'Tu documento ha sido firmado electronicamente utilizando Fingerink':'Tu documento ha sido firmado electronicamente utilizando Fingerink',
    'Si sientes curiosidad sobre como funciona Fingerink, puedes consultar toda la información que necesites en nuestra web':'Si sientes curiosidad sobre como funciona Fingerink, puedes consultar toda la información que necesites en nuestra web',
    'Debes aceptar la privacidad y los términos y condiciones':'Debes aceptar la privacidad y los términos y condiciones',
    'Firma Simple':'Firma Simple',
    'Documento a firmar':'Documento a firmar',
    'Añadir desde contactos':'Añadir desde contactos',
    'Modalidad de firma':'Modalidad de firma',
    'Requerir DNI':'Requerir DNI',
    'Mensaje':'Mensaje',
    'Cambiar email':'Cambiar email',
    'Enviar recordatorio':'Enviar recordatorio',
    'Filtros':'Filtros',
    'Categorias':'Categorias',
    'Fechas':'Fechas',
    'Sin filtro':'Sin filtro',
    'Hoy':'Hoy',
    'Esta semana':'Esta semana',
    'Este mes':'Este mes',
    'Elegir':'Elegir',
    'Se solicita la rúbrica a tus firmantes':'Se solicita la rúbrica a tus firmantes',
    'El documento se firma con un botón':'El documento se firma con un botón. Es como aceptar los términos y condiciones de un servicio',
    'Equivale a un envío certificado':'Equivale a un envío certificado',
    'El documento se firma sobre el propio dispositivo':'El documento se firma sobre el propio dispositivo',
    'Firma el documento tu mismo':'Firma el documento tu mismo',
    'Descripción de campo':'Descripción de campo',
    'Creando plantilla':'Creando plantilla',
    'Nº de licencias contratadas':'Nº de licencias contratadas',
    'Licencia':'Licencia',

    'Empieza a enviar y firmar documentos ahora con Fingerink':'Empieza a enviar y firmar documentos ahora con Fingerink',
    'Sube un documento y selecciona el tipo de firma que quieras realizar':'Sube un documento y selecciona el tipo de firma que quieras realizar',
    'Añade los firmantes del documento y elige la modalidad de firma':'Añade los firmantes del documento y elige la modalidad de firma',
    'Añade al documento los campos que deben rellenar los firmantes':'Añade al documento los campos que deben rellenar los firmantes',
    'Los firmantes recibirán el documento a su email, donde podrán firmarlo':'Los firmantes recibirán el documento a su email, donde podrán firmarlo',
    'Personaliza tus envíos. Añade el logo de tu empresa si quieres que les aparezca a tus firmantes':'Personaliza tus envíos. Añade el logo de tu empresa si quieres que les aparezca a tus firmantes',
    'Por último, rellena tus datos de facturación y recuerda que no tienes que pagar nada ahora':'Por último, rellena tus datos de facturación y recuerda que no tienes que pagar nada ahora',

    


    
   




    
    



    '':''

};
